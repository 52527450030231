import english from './en'
import french from './fr'
import german from './de'
import { i18n } from 'arketiks-tools'

export default ({
  locale: localStorage.getItem('language') || 'en',
  messages: {
    en: { ...i18n.en, ...english },
    fr: { ...i18n.fr, ...french },
    de: { ...i18n.de, ...german }
  }
})
