export default {
  appName: 'Arketiks Quiz',
  delete: 'Delete',
  rename: 'Rename',
  search: 'Search',
  cancel: 'Cancel',
  create: 'Create',
  save: 'Save',
  general: {
    quiz: 'Quiz',
    quizzes: 'Quizzes',
    search: 'Search',
    sortByDate: 'Sort by date',
    sortByName: 'Sort by name',
    title: 'Quiz title',
    introduction: 'Introduction message',
    introImage: 'Introduction image',
    language: 'Language',
    bg: 'Background',
    yes: 'Yes',
    no: 'No',
    open: 'Open',
    close: 'Close'
  },
  actions: {
    delete: 'Delete',
    rename: 'Rename',
    save: 'Save',
    duplicate: 'Duplicate in',
    logout: 'Logout',
    addAnImage: 'Add an image',
    close: 'Close'
  },
  data: {
    questions: 'questions',
    minutes: 'minutes',
    attempt: 'Remaining attempts',
    feedbackButtonText: 'Feedback',
    finalFeedbackButtonText: 'Feedback',
    startMessage: 'Start',
    submitMessage: 'Submit answers',
    submitConfirmation: 'Are you sure?',
    submitCancelation: 'No',
    successMessage: 'Good job!',
    failureMessage: 'Sorry. Your score is not enough. Try again.',
    errorMessage: 'For technical reasons, your tracking results with respect to the test could not be saved. Please take a screenshot and send it to the person concerned. Thank you.',
    hints: {
      mcq: 'Choose the correct answers.',
      scq: 'Choose the correct answer.',
      gapFill: 'Fill each gap in the text with the correct answer.'
    }
  },
  customization: {
    look: 'Look',
    settings: 'Settings',
    successMessage: 'Success Message',
    failureMessage: 'Failure Message',
    retryButtonText: 'Retry button text',
    scormNotification: 'SCORM error notifications',
    expiration: 'Expiration date',
    hideExitButton: 'Hide exit button'
  },
  languages: {
    en: 'English',
    fr: 'French',
    de: 'German'
  },
  projects: {
    projects: 'Projects',
    newProject: 'New Project',
    projectName: 'Project Name',
    newQuiz: 'New Quiz',
    quizName: 'Quiz Name',
    sortByDate: 'Sort By Date',
    sortByName: 'Sort By Name'
  },
  quiz: {
    name: 'Quiz name',
    mcq: 'Multiple choice question',
    scq: 'Single choice question',
    gapfill: 'Text with gaps',
    customization: 'Customization',
    translate: 'Translate',
    from: 'From',
    to: 'To',
    successScore: 'Success score (%)',
    timeToFinish: 'Minutes to finish the quiz',
    attempt: 'Attempt',
    shortcuts: 'Quiz shortcuts',
    numberOfDisplayedQuestions: 'Nb of displayed questions',
    category: 'Theme',
    addACategory: 'Add a theme',
    question: 'Question',
    addAQuestion: 'Add a question',
    answer: 'Answer',
    addAnAnswer: 'Add an answer',
    true: 'True',
    false: 'False',
    toCheck: 'To check',
    feedback: {
      feedback: 'Feedback',
      generalFeedback: 'General feedback',
      showFeedbackAtTheEnd: 'Display the feedback button at the end of the quiz',
      showFeedbackIfFailed: 'Show feedback if the quiz was failed',
      showIndividualFeedback: 'Display individual feedback button for every question',
      showCorrection: 'Show the correction with the feedback',
      showCorrectionAtTheEnd: 'Show the correction with the feedback only at the end of the quiz'
    },
    export: {
      export: 'Export',
      sco: 'Export SCO',
      onlineSco: 'Export online SCO',
      mcq: 'Export text',
      link: 'Quiz link',
      linkExplanation: `<p>The quiz is accessible on <a href="{link}" target="_blank">{link}</a></p>
  <p>You can easily integrate it in an iframe to display the quiz on your website like on the following exemple.</p>
  <p>&lt;iframe width="300" height="200" src="{link}" /&gt;</p>`
    },
    getMCQTemplate: 'Upload MCQ template',
    delete: 'Delete Quiz',
    deleteProject: 'Delete project',
    deleteProjectConfirmation: 'Do you really want to delete this project?',
    deleteConfirmation: 'Do you really want to delete this quiz?',
    deleteConfirmationAction: 'Write « delete » to confirm.',
    deleteConfirmationText: 'delete',
    deleteQuestion: 'Delete question',
    deleteQuestionConfirmation: 'Do you really want to delete this question?',
    generateQuestions: 'Generate questions',
    generationText: 'Source text (smaller than 1000 words)',
    generate: 'Generate'
  },
  gapfill: {
    help: 'Write the text and select the words you want to hide.',
    suggestions: 'Suggestions for',
    addAFalseSuggestion: 'Add a false suggestion'
  },
  translations: {
    af: 'Afrikaans',
    sq: 'Albanian',
    am: 'Amharic',
    ar: 'Arabic',
    hy: 'Armenian',
    az: 'Azerbaijani',
    eu: 'Basque',
    be: 'Belarusian',
    bn: 'Bengali',
    bs: 'Bosnian',
    bg: 'Bulgarian',
    ca: 'Catalan',
    ceb: 'Cebuano',
    'zh-CN': 'Chinese (Simplified)',
    'zh-TW': 'Chinese (Traditional)',
    co: 'Corsican',
    hr: 'Croatian',
    cs: 'Czech',
    da: 'Danish',
    nl: 'Dutch',
    en: 'English',
    eo: 'Esperanto',
    et: 'Estonian',
    fi: 'Finnish',
    fr: 'French',
    fy: 'Frisian',
    gl: 'Galician',
    ka: 'Georgian',
    de: 'German',
    el: 'Greek',
    gu: 'Gujarati',
    ht: 'Haitian Creole',
    ha: 'Hausa',
    haw: 'Hawaiian',
    he: 'Hebrew',
    hi: 'Hindi',
    hmn: 'Hmong',
    hu: 'Hungarian',
    is: 'Icelandic',
    ig: 'Igbo',
    id: 'Indonesian',
    ga: 'Irish',
    it: 'Italian',
    ja: 'Japanese',
    jw: 'Javanese',
    kn: 'Kannada',
    kk: 'Kazakh',
    km: 'Khmer',
    ko: 'Korean',
    ku: 'Kurdish',
    ky: 'Kyrgyz',
    lo: 'Lao',
    la: 'Latin',
    lv: 'Latvian',
    lt: 'Lithuanian',
    lb: 'Luxembourgish',
    mk: 'Macedonian',
    mg: 'Malagasy',
    ms: 'Malay',
    ml: 'Malayalam',
    mt: 'Maltese',
    mi: 'Maori',
    mr: 'Marathi',
    mn: 'Mongolian',
    my: 'Myanmar (Burmese)',
    ne: 'Nepali',
    no: 'Norwegian',
    ny: 'Nyanja (Chichewa)',
    ps: 'Pashto',
    fa: 'Persian',
    pl: 'Polish',
    pt: 'Portuguese (Portugal, Brazil)',
    pa: 'Punjabi',
    ro: 'Romanian',
    ru: 'Russian',
    sm: 'Samoan',
    gd: 'Scots Gaelic',
    sr: 'Serbian',
    st: 'Sesotho',
    sn: 'Shona',
    sd: 'Sindhi',
    si: 'Sinhala (Sinhalese)',
    sk: 'Slovak',
    sl: 'Slovenian',
    so: 'Somali',
    es: 'Spanish',
    su: 'Sundanese',
    sw: 'Swahili',
    sv: 'Swedish',
    tl: 'Tagalog (Filipino)',
    tg: 'Tajik',
    ta: 'Tamil',
    te: 'Telugu',
    th: 'Thai',
    tr: 'Turkish',
    uk: 'Ukrainian',
    ur: 'Urdu',
    uz: 'Uzbek',
    vi: 'Vietnamese',
    cy: 'Welsh',
    xh: 'Xhosa',
    yi: 'Yiddish',
    yo: 'Yoruba',
    zu: 'Zulu'
  },
  notifications: {
    checkConnection: 'Check your connection and try again.'
  }
}
