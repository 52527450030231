import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { TheOrganizations } from 'arketiks-tools'

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')
    },
    {
      path: '/unauthorized',
      name: 'noOrg',
      component: () => import(/* webpackChunkName: "noOrg" */ '@/views/NoOrg')
    },
    {
      path: '/orgs',
      name: 'organizations',
      component: TheOrganizations,
      props: () => ({ orgs: useUserStore().organizations }),
      beforeEnter: hasAccess
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter: (to, from, next) => {
        useUserStore().logout()
        next({ name: 'home' })
      }
    },
    {
      path: '/:org/projects',
      alias: '/:org',
      name: 'projects',
      component: () => import(/* webpackChunkName: "projects" */ './views/Quizzes.vue'),
      beforeEnter: hasAccess
    },
    {
      path: '/:org/editor/:projectId/:quizId',
      name: 'editor',
      component: () => import(/* webpackChunkName: "editor" */ './views/Editor.vue'),
      beforeEnter: hasAccess
    },
    {
      path: '/*',
      redirect: { name: 'home' }
    }
  ]
})

function hasAccess (to) {
  return useUserStore().user
    ? true
    : { name: 'home', query: { redirect: to?.fullPath }, params: to.params }
}
