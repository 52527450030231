import { initializeApp } from 'firebase/app'
import { initializeFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY || 'AIzaSyB75h9lcwsj5yuSSL0Hn-0VHcxhq9EDUbs',
  authDomain: 'quiz.arketiks.com',
  projectId: 'quiz-fd573',
  storageBucket: 'quiz-fd573.appspot.com',
  messagingSenderId: '787312172316',
  appId: '1:787312172316:web:2ed349e5984d4786ec6ed9',
  measurementId: 'G-6J72G8J3VD'
}

const app = initializeApp(firebaseConfig)
initializeFirestore(app, { experimentalAutoDetectLongPolling: true })
getAnalytics(app)

if (process.env.NODE_ENV === 'development') {
  import('./firebase-emulate')
}
