export default {
  appName: 'Quiz Arketiks',
  delete: 'Supprimer',
  rename: 'Renommer',
  search: 'Chercher',
  cancel: 'Annuler',
  create: 'Créer',
  save: 'Sauver',
  general: {
    quiz: 'Quiz',
    quizzes: 'Vos quiz',
    search: 'Rechercher',
    sortByDate: 'Trie par date',
    sortByName: 'Trie par nom',
    title: 'Titre du quiz',
    introduction: `Message d'introduction`,
    introImage: `Image d'introduction`,
    language: 'Langue',
    bg: 'Image de fond',
    yes: 'Oui',
    no: 'Non',
    open: 'Ouvrir',
    close: 'Fermer'
  },
  actions: {
    delete: 'Supprimer',
    rename: 'Renommer',
    save: 'Enregistrer',
    duplicate: 'Dupliquer dans',
    logout: 'Déconnexion',
    addAnImage: 'Ajouter une image',
    close: 'Fermer'
  },
  data: {
    questions: 'questions',
    minutes: 'minutes',
    attempt: 'Essai restant',
    feedbackButtonText: 'Vérifier',
    finalFeedbackButtonText: `Vérifier l'ensemble des réponses`,
    startMessage: 'Démarrer',
    submitMessage: 'Terminer et voir mon résultat',
    submitConfirmation: 'Etes-vous sûr ?',
    submitCancelation: 'Non',
    successMessage: 'Bravo!',
    failureMessage: `Hélas votre score n'est pas suffisant. Essayez encore.`,
    errorMessage: 'Pour des raisons techniques, le résultat de votre test n\'a pas pu être enregistré. Nous vous prions de faire une capture d\'écran et de la transmettre à la personne concernée. Nous vous en remercions.',
    hints: {
      mcq: 'Choisissez les réponses correctes.',
      scq: 'Choisissez la réponse correcte.',
      gapFill: 'Remplissez chaque trou dans le text avec la réponse correcte.'
    }
  },
  customization: {
    look: 'Présentation',
    settings: 'Options',
    successMessage: 'Message de réussite',
    failureMessage: `Message d'échec`,
    retryButtonText: 'Texte du bouton réessayer',
    scormNotification: 'SCORM notifications d\'erreur',
    expiration: `Date d'expiration`,
    hideExitButton: 'Cacher le bouton de fermeture'
  },
  languages: {
    en: 'Anglais',
    fr: 'Français',
    de: 'Allemand'
  },
  projects: {
    projects: 'Projets',
    newProject: 'Nouveau Projet',
    projectName: 'Nom du Projet',
    newQuiz: 'Nouvelle Quiz',
    quizName: 'Nom du Quiz',
    sortByDate: 'Trier Par Date',
    sortByName: 'Trier par Nom'
  },
  quiz: {
    name: 'Nom du quiz',
    createAQuiz: 'Créer un quiz',
    createAProject: 'Créer un projet',
    mcq: 'Question à choix multiple',
    scq: 'Question à choix unique',
    gapfill: 'Texte à trous',
    customization: 'Personnalisation',
    translate: 'Traduire',
    from: 'De',
    to: 'À',
    successScore: 'Score de réussite (%)',
    timeToFinish: 'Temps à disposition pour faire le quiz',
    attempt: 'Essai',
    shortcuts: 'Sommaire',
    numberOfDisplayedQuestions: 'Nb de question sélectionnées',
    category: 'Thème',
    addACategory: 'Ajouter un thème',
    question: 'Question',
    addAQuestion: 'Ajouter une question',
    answer: 'Réponse',
    addAnAnswer: 'Ajouter une réponse',
    true: 'Vrai',
    false: 'Faux',
    toCheck: 'A vérifier',
    feedback: {
      feedback: 'Feedback',
      generalFeedback: 'Feedback général',
      showFeedbackAtTheEnd: 'Afficher le bouton feedback seulement à la fin du quiz',
      showFeedbackIfFailed: `Afficher les feedback si le quiz n'est pas réussi`,
      showIndividualFeedback: 'Afficher le bouton feedback à chaque question',
      showCorrection: 'Afficher la correction avec le feeback',
      showCorrectionAtTheEnd: 'Afficher la correction avec le feeback seulement à la fin du quiz'
    },
    export: {
      export: 'Export',
      sco: 'Exporter le SCO',
      onlineSco: 'Exporter le SCO en ligne',
      mcq: 'Exporter le texte',
      link: 'Lien du quiz',
      linkExplanation: `<p>Le quiz est accessible sur <a href="{link}" target="_blank">{link}</a></p>
      <p>Vous pouvez facilement utiliser ce lien dans une iframe pour montrer le quiz sur votre site comme sur l'example suivant.</p>
      <p>&lt;iframe width="300" height="200" src="{link}" /&gt;</p>`
    },
    getMCQTemplate: 'Télécharger un template du QCM',
    delete: 'Supprimer le quiz',
    deleteProject: 'Supprimer le projet',
    deleteProjectConfirmation: 'Voulez-vous vraiment supprimer ce projet?',
    deleteConfirmation: 'Voulez-vous vraiment supprimer le quiz?',
    deleteConfirmationAction: 'Ecrire « supprimer » pour confirmer.',
    deleteConfirmationText: 'supprimer',
    deleteQuestion: 'Supprimer la question',
    deleteQuestionConfirmation: 'Voulez-vous vraiment supprimer cette question?',
    generateQuestions: 'Générer des questions',
    generationText: 'Texte source (moins de 1000 mots)',
    generate: 'Générer'
  },
  gapfill: {
    help: 'Ecrivez le texte, puis sélectionnez les mots que vous souhaitez cacher.',
    suggestions: 'Propositions pour',
    addAFalseSuggestion: 'Ajouter une fausse proposition'
  },
  notifications: {
    checkConnection: 'Vérifiez votre connexion et essayer à nouveau.'
  }
}
