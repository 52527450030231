<template>
  <div id="app">

    <nav
      v-if="$route.params.org"
      class="m0 theme-strong"
    >
      <label
        class="toggle"
        for="nav-toggle"
      >
        <div/>
        <div/>
      </label>
      <input
        id="nav-toggle"
        type="checkbox"
      >
      <router-link
        :to="{ name: 'projects' }"
        class="logo ml2 m"
      />
      <div class="content">
        <router-link
          :to="{ name: 'projects' }"
          class="mr"
        >
          {{ $t('general.quizzes') }}
        </router-link>
        <div class="dropdown">
          <button class="theme p0">
            <img
              :src="`/assets/lang/${$i18n.locale}.png`"
              :alt="$i18n.locale"
              height="14"
            >
          </button>
          <div class="dropdown__content p0">
            <button
              v-for="lang in ['en', 'fr', 'de']"
              :key="lang"
              class="theme-soft p0"
              @click="setLanguage(lang)"
            >
              <img
                :src="`/assets/lang/${lang}.png`"
                :alt="lang"
                height="16"
              >
            </button>
          </div>
        </div>
        <router-link :to="{ name: 'logout' }">
          {{ $t('actions.logout') }}
        </router-link>
      </div>
    </nav>

    <router-view v-slot="{ Component }">
      <keep-alive include="Quizzes">
        <component
          :is="Component"
          class="small"
        />
      </keep-alive>
    </router-view>

    <ConfirmationPopup />
    <TheNotifications />
    <TheLoading />
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { TheLoading, ConfirmationPopup, TheNotifications } from 'arketiks-tools'
import { useUserStore } from './stores/user'

export default {
  name: 'app',
  components: {
    ConfirmationPopup,
    TheNotifications,
    TheLoading
  },
  computed: mapState(useUserStore, [
    'user'
  ]),
  methods: {
    setLanguage (language) {
      this.$i18n.locale = language
      localStorage.setItem('language', language)
    }
  }
}
</script>

<style>
body {
  background: var(--color-background-strong) !important;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
.logo {
  background-image: url(./assets/logo.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 2rem;
  height: 2rem;
  padding: 0;
  margin: 0.25rem 0.5rem;
  border-radius: 3px;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

button,
a {
  transition: all .3s;
}
</style>
