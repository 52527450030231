import {
  faTrashAlt,
  faCheckCircle,
  faTimesCircle,
  faFileImage,
  faSquare,
  faCheckSquare,
  faSpinner,
  faTimes
} from '@fortawesome/pro-light-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(
  faTrashAlt,
  faCheckCircle,
  faTimesCircle,
  faFileImage,
  faSquare,
  faCheckSquare,
  faSpinner,
  faTimes
)
