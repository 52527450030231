import { defineStore } from 'pinia'
import { getAuth } from 'firebase/auth'
import router from '@/router'
import { api, useUIStore } from 'arketiks-tools'

const auth = getAuth()

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
    organization: null,
    organizations: []
  }),
  actions: {
    async getUser (authUser) {
      if (this.user) return this.user
      authUser = authUser || auth.currentUser
      if (authUser) {
        if (this.user && this.organizations) return this.user
        let user = await api.getUser(authUser)
        try {
          if (router.currentRoute.value.redirectedFrom?.name === 'organizations' || !user.orgs.length) {
            user = await api.updateOrgs(user)
          }

          if (user.orgs.length === 1 || !router.currentRoute.value.redirectedFrom?.params.org) {
            api.setOrganizationId(user.defaultOrg)
            this.organization = { id: user.defaultOrg, alias: router.currentRoute.value.redirectedFrom?.params.org || '0' }
            await api.getOrgUser(user)
            this.user = user
            await router.replace(router.currentRoute.value.redirectedFrom || { name: 'projects', params: { org: '0' } })
            useUIStore().setLoading(false)
          }

          this.organizations = await api.getOrganizations(user)
            .catch(async () => {
              await api.updateOrgs(user)
              return api.getOrganizations(user)
            })
          if (!this.organization && this.organizations.length) {
            await this.getOrgUser(user)
          } else if (this.organization.alias === '0') {
            this.organization.alias = this.organizations
              .find(o => o.id === this.organization.id).alias
          }
        } catch {
          await api.updateOrgs(user)
          if (user.orgs.length) {
            if (!api.getOrganizationId()) {
              api.setOrganizationId(user.orgs[0])
            }
            if (user.orgs.includes(api.getOrganizationId())) {
              await api.getOrgUser(user)
            }
          } else {
            api.setOrganizationId(null)
          }
        }
        this.user = user
        if (this.organization) {
          this.organization = this.organizations.find(o => o.id === this.organization.id)
          if (router.currentRoute.value.redirectedFrom?.params.org) {
            await router.replace(router.currentRoute.value.redirectedFrom)
          } else {
            await router.replace({ name: router.currentRoute.value.name === 'home' ? 'projects' : router.currentRoute.value.name, params: { org: this.organization.alias } })
          }
        } else {
          await router.push({ name: 'noOrg', query: { org: api.getOrganizationId() } })
        }
      }
    },
    async getOrgUser (user) {
      const orgAlias = router.currentRoute.value.redirectedFrom?.params.org
      this.organization = this.organizations
        .find(o => o.alias.toLowerCase() === orgAlias.toLowerCase())
      if (this.organization) {
        api.setOrganizationId(this.organization.id)
        return api.getOrgUser(user)
      }
      return user
    },
    logout () {
      this.$reset()
      auth.signOut()
    }
  }
})
