import { createApp } from 'vue'
import './firebase'
import App from './App.vue'
import router from './router'
import store from './stores'
import { createI18n } from 'vue-i18n'
import translations from './i18n'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { TextInput, AppButton } from 'arketiks-tools'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './assets/fontawesome'

import 'goku-css'
import 'arketiks-tools/dist/style.css'
import './css/index.css'

const i18n = createI18n(translations)
const app = createApp(App)
app
  .use(store)
  .use(router)
  .use(i18n)
  .component('FontAwesomeIcon', FontAwesomeIcon)
  .component('AppButton', AppButton)
  .component('TextInput', TextInput)
  .mount('#app')

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: 'https://91cd0e4dc78742c287ee0b6b05d8480a@sentry.io/1376123',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['quiz.arketiks.com', /^\//]
      })
    ],
    maxValueLength: 5000,
    tracesSampleRate: 0.1
  })
}
