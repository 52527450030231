export default {
  appName: 'Quiz Arketiks',
  delete: 'Löschen',
  rename: 'Umbenennen',
  search: 'Suche',
  cancel: 'Abbrechen',
  create: 'Erstellen',
  save: 'Speichern',
  general: {
    quiz: 'Quiz',
    quizzes: 'Ihre quiz',
    search: 'Suchen',
    sortByDate: 'Nach Datum sortieren',
    sortByName: 'Nach Name sortieren',
    title: 'Titel des Quiz',
    introduction: `Einleitungstext`,
    introImage: `Einführendes Bild`,
    language: 'Sprache',
    bg: 'Hintergrundbild',
    yes: 'Ja',
    no: 'Nein',
    open: 'Öffnen',
    close: 'Schließen'
  },
  actions: {
    delete: 'Entfernen',
    rename: 'Wieder einstellen',
    save: 'Speichern',
    duplicate: 'Duplizieren in',
    logout: 'Unterbrechung',
    addAnImage: 'Bild hinzufügen',
    close: 'Schließen'
  },
  data: {
    questions: 'fragen',
    minutes: 'minuten',
    attempt: 'Verbleibende Versuche',
    feedbackButtonText: 'Korrektur',
    finalFeedbackButtonText: 'Alle Antworten überprüfen',
    startMessage: 'Start',
    submitMessage: 'Die Antwort überprüfen',
    submitConfirmation: 'Sind Sie sicher ?',
    submitCancelation: 'Nein',
    successMessage: 'Bravo!',
    failureMessage: `Leider, Ihr Ergebnis ist nicht genug. Versuchen Sie noch einmal.`,
    errorMessage: 'Aus technischen Gründen konnte Ihr Testergebnis nicht gespeichert werden. Bitte machen Sie einen Screenshot und senden Sie ihn an die betreffende Person. Wir danken Ihnen.',
    hints: {
      mcq: 'Wählen Sie die richtigen Antworten.',
      scq: 'Wählen Sie die richtige Antwort.',
      gapFill: 'Füllen Sie jedes Loch im Text mit der richtigen Antwort.'
    }
  },
  customization: {
    look: 'Blick',
    settings: 'Optionen',
    successMessage: 'Erfolgsmeldung',
    failureMessage: 'Fehlermeldung',
    retryButtonText: 'Wiederholen Sie den Text der Schaltfläche',
    scormNotification: 'SCORM Fehlermeldung',
    expiration: 'Ablauf',
    hideExitButton: 'Exit-Taste ausblenden'
  },
  languages: {
    en: 'Englisch',
    fr: 'Französisch',
    de: 'Deutsch'
  },
  project: {
    projects: 'Projekte',
    newProject: 'Neues Projekt',
    projectName: 'Projektname',
    newVideo: 'Neues Video',
    videoName: 'Videoname',
    sortByDate: 'Nach Datum sortieren',
    sortByName: 'Nach Namen sortieren'
  },
  quiz: {
    name: 'Name des Quiz',
    createAQuiz: 'Ein Quiz erstellen',
    createAProject: 'Ein projekt erstellen',
    mcq: 'Multiple-Choice-Frage',
    scq: 'Einzelwahlfrage',
    gapfill: 'Lochtext',
    customization: 'Personalisierung',
    translate: 'Übersetzen',
    from: 'Von',
    to: 'Zu',
    successScore: 'Erfolgsquote (%)',
    timeToFinish: 'Verfügbare Zeit, um der Quiz zu machen',
    attempt: 'Versuch',
    shortcuts: 'Zusammenfassung',
    numberOfDisplayedQuestions: 'Anzahl der ausgewählten Fragen',
    category: 'Thema',
    addACategory: 'Ein Thema hinzufügen',
    question: 'Fragen',
    addAQuestion: 'Eine Frage hinzufügen',
    answer: 'Antwort',
    addAnAnswer: 'Eine Antwort hinzufügen',
    true: 'Wahr',
    false: 'Falsch',
    toCheck: 'Zu überprüfen',
    feedback: {
      feedback: 'Feedback',
      generalFeedback: 'Allgemeines Feedback',
      showFeedbackAtTheEnd: 'Zeige die Feedback-Schaltfläche nur am Ende des Quiz',
      showFeedbackIfFailed: 'Feedback anzeigen, wenn das Quiz fehlgeschlagen ist',
      showIndividualFeedback: 'Zeige die Feedback-Schaltfläche zu jeder Frage',
      showCorrection: 'Zeigen Sie die Korrektur mit das Feedback',
      showCorrectionAtTheEnd: 'Zeigen Sie die Korrektur erst am Ende des Quiz mit das Feedback an'
    },
    export: {
      export: 'Export',
      sco: 'Exportieren der SCO',
      onlineSco: 'Exportieren Sie den SCO online',
      mcq: 'Text exportieren',
      link: 'Quiz link',
      linkExplanation: `<p>Das Quiz ist am zugänglich <a href="{link}" target="_blank">{link}</a></p>
  <p>Sie können es einfach in einen Iframe integrieren, um das Quiz auf Ihrer Website wie im folgenden Beispiel anzuzeigen.</p>
  <p>&lt;iframe width="300" height="200" src="{link}" /&gt;</p>`
    },
    getMCQTemplate: 'Laden Sie eine QCM-Vorlage herunter',
    delete: 'Quiz löschen',
    deleteProject: 'Projekt löschen',
    deleteProjectConfirmation: 'Möchten Sie das projekt wirklich löschen?',
    deleteConfirmation: 'Möchten Sie das Quiz wirklich löschen?',
    deleteConfirmationAction: 'Schreiben Sie zur Bestätigung "Löschen".',
    deleteConfirmationText: 'löschen',
    deleteQuestion: 'Lösche die Frage',
    deleteQuestionConfirmation: 'Möchten Sie diese Frage wirklich löschen?',
    generateQuestions: 'Fragen generieren',
    generationText: 'Quelltext (kleiner als 1000 Wörter)',
    generieren: 'Generieren'
  },
  gapfill: {
    help: 'Schreiben Sie den Text und wählen Sie die Wörter aus, die Sie ausblenden möchten.',
    suggestions: 'Vorschläge für',
    addAFalseSuggestion: 'Fügen Sie einen falschen Vorschlag hinzu'
  },
  notifications: {
    checkConnection: 'Überprüfen Sie Ihre Verbindung und versuchen Sie noch einmal.'
  }
}
